<template>
    <div class="card">
        <Loader v-model="loading" />
        <Helper v-model="GeneratorHelper" header="Buscar Codigo Generado" :headers="Headers" :rows="entities" @selected="selectEntity" />
        <div class="p-grid">
            <div class="p-col-12">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-col-12">
                        <BasicFormToolbar @save="save" @search="GeneratorHelper.visible = true" @deleted="deleteEntity()" :actions="['save', 'search']">
                            <template #custom-left>
                                <Button @click="deleteEntity" class="p-button-danger mr-4"> Eliminar </Button>
                                <Button @click="GenerateProps" class="p-button-success mr-4"> PROP,JS </Button>
                            </template>
                        </BasicFormToolbar>
                    </div>
                </div>
            </div>
            <div id="Code">
                <div>
                    <Fieldset legend="Generador de Codigo" :collapsed="false" :toggleable="false">
                        <div class="formgrid grid p-fluid">
                            <FormDropdownComplex
                                placeholder="Seleciona una tabla..."
                                :labelInOption="'table_Name'"
                                :labelInValue="'table_Name'"
                                :wrapperClass="'field col-12 md:col-4 xl:col-4'"
                                label="Tablas"
                                v-model="entity.name"
                                :options="tables"
                                optionLabel="table_Name"
                                optionValue="table_Name"
                            />
                            <FormInputText v-model="entity.folder_father" :wrapperClass="'field col-12 md:col-4 xl:col-4'" :label="'Carpeta del modelo Tootle-ERP-API'" />
                            <FormInputText v-model="entity.carpetavue" :wrapperClass="'field col-12 md:col-4 xl:col-4'" :label="'Carpeta del modelo Vue'" />

                            <div class="'field col-12 md:col-3 xl:col-6'">
                                <RadioButton name="Version 1" value="1" v-model="entity.vue" />
                                <label for="rb1">Version 1 </label>
                                <br>
                                <br>
                                <a href="C:\Users\germa\Documents\erp_atlantis\public\images\icons\GeneradorCode\Diapositiva1.png"
                                    ><img class="'field col-12 md:col-12 xl:col-12'" src="../../../../public/images/icons/GeneradorCode/Diapositiva1.png" alt="Image Text" />
                                </a>
                            </div>
                            <div class="'field col-12 md:col-3 xl:col-4'">
                                <RadioButton name="Version 2" value="2" v-model="entity.vue" />
                                <label for="rb1">Version 2 </label>
                                <br>
                                <br>
                                <img class="'field col-12 md:col-12 xl:col-12'" src="../../../../public/images/icons/GeneradorCode/Diapositiva2.png" alt="Image Text" />
                                <br>
                                <br>
                            </div>
                            <div class="'field col-12 md:col-3 xl:col-4'">
                                <RadioButton name="Version 3" value="3" v-model="entity.vue" />
                                <label for="rb1">Version 3 </label>
                                <br>
                                <br>
                                <img class="'field col-12 md:col-12 xl:col-12'" src="../../../../public/images/icons/GeneradorCode/Diapositiva3.png" alt="Image Text" />
                            </div>
                            <div class="'field col-12 md:col-3 xl:col-4'">
                                <RadioButton name="Version Catalog" value="4" v-model="entity.vue" />
                                <label for="rb1">Version Catalog </label>
                                <br>
                                <br>
                                <img class="'field col-12 md:col-12 xl:col-12'" src="../../../../public/images/icons/GeneradorCode/Diapositiva2.png" alt="Image Text" />
                            </div>
                            <br>
                            <br>

                            <div class="'field col-12 md:col-12 xl:col-12'">
                                <br>
                                <br>
                                <div class="'field col-12 md:col-12 xl:col-4'">
                                    <label for="name"
                                        >Nota:"PARA LOS TIPOS DE DATOS DE INPUT DE INT ,STRING AGREGAR "DROPDOWN" CON LA SIGUIENTE SINTAXIS = public int? status DROPDOWN { get; set; } EN PROP SI LO QUE QUIERES CAMBIAR EL INPUT DEFAULT DE TEXT X
                                        DROPDOWN</label
                                    >
                                    <br>
                                </div>

                                <div class="'field col-12 md:col-12 xl:col-4'">
                                    <label for="name"
                                        >Nota:"PARA LOS TIPOS DE DATOS BOOLEAN Y BIT AGREGAR "SWITCH" CON LA SIGUIENTE SINTAXIS = public int? status SWITCH { get; set; } EN PROP SI LO QUE QUIERES CAMBIAR EL INPUT DEFAULT DE TEXT X SWITCH</label
                                    >
                                    <br>
                                </div>
                                <br>
                                <br>
                            </div>

                            <div class="'field col-12 md:col-6 xl:col-4'">
                                <label for="name">Prop</label> <br>
                                <Textarea id="name" v-model.trim="entity.prop" :class="{ 'p-invalid': validate.validations.prop === false }" :autoResize="true" rows="5" cols="30" />
                                <small class="p-invalid" v-if="validate.validations.prop === false">Favor de llenar el campo Nombre de la tabla</small>
                            </div>
                            <div class="'field col-12 md:col-6 xl:col-4'">
                                <label for="name">JS</label> <br>
                                <Textarea :disabled="true"   id="name" v-model.trim="entity.js" :class="{ 'p-invalid': validate.validations.js === false }" :autoResize="true" rows="5" cols="30" />
                                <small class="p-invalid" v-if="validate.validations.js === false">Favor de llenar el campo Nombre de la tabla</small>
                            </div>
                            <br>
                            <br>
                        </div>
                    </Fieldset>
                </div>
            </div>
        </div>
    </div>
</template> 

<script>
import { GeneratorCode } from '../../../models/general/GenerateCode';
import { HeaderGrid, Rule, ErrorToast, validate, fillObject, Toast } from '../../../utilities/General';
import Helper from '../../../components/general/HelperDialog.vue';
import Loader from '../../../components/general/Loader.vue';
import Session from '../../../mixins/sessionMixin';
// import BasicDatatable from '../../../components/general/BasicDatatable.vue';
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue';
import FormDropdownComplex from '../../../components/general/FormDropdownComplex.vue';
import FormInputText from '../../../components/general/FormInputText.vue';

export default {
    mixins: [Session],
    components: { Helper, Loader, BasicFormToolbar, FormDropdownComplex, FormInputText },
    data() {
        return {
            tables: [],
            entity: null,
            entities: [],
            GeneratorCode: [],
            GeneratorHelper: {
                visible: false,
            },
            loading: false,
            newDialog: false,
            deleteDialog: false,
            Headers: [new HeaderGrid('Nombre', 'name'), new HeaderGrid('Prop', 'prop'), new HeaderGrid('JS', 'js')],
            rules: [new Rule({ name: 'name' }), new Rule({ name: 'folder_father' }), new Rule({ name: 'prop' }), new Rule({ name: 'js' }), new Rule({ name: 'carpetavue' })],
            validate: {
                valid: false,
                validations: {
                    name: null,
                    folder_father: null,
                    prop: null,
                    js: null,
                    controller: null,
                    ModelVue: null,
                    Model: null,
                    Vue: null,
                },
            },

            customerHelper: {
                visible: false,
            },
        };
    },
    created() {
        console.log(this.session);
        this.entity = new GeneratorCode(this.session);
    },
    async mounted() {
        await this.refresh();
    },
    watch: {
        'entity.currency'(newValue) {
            if (newValue == 'MXN') {
                this.entity.exchange_rate = 1.0;
            }
        },
    },
    methods: {
        openNew() {
            this.entity = GeneratorCode(this.session);
            this.newDialog = true;
        },
        hideDialog() {
            this.newDialog = false;
        },

        selectEntity(tm_prueba) {
            this.entity = fillObject(this.entity, tm_prueba);
        },

        async refresh() {
            this.loading = true;
            try {
                this.entities = await this.entity.all();

                this.tables = await new GeneratorCode(this.session).tablas();
                // this.tables.forEach(element =>{console.log(element.table_Name)

                //console.log(this.tables[0].table_Name)
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        confirmDelete(entity) {
            this.entity = fillObject(this.entity, entity);
            this.deleteDialog = true;
        },

        async deleteEntity() {
            try {
                this.loading = true;
                // Eliminamos de la base  */
                await this.entity.delete();
                //* Eliminamos de la vista*/

                // Limpiamos la entidad */
                this.entity = new GeneratorCode(this.session);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Eliminacion',
                    detail: 'Registro eliminado con exito',
                    life: 3000,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async GenerateProps() {
            this.Generador = await new GeneratorCode(this.session).balance(this.entity.name);
            console.log(this.Generador[0].prop);
            // const types = [...new Set(this.Generador.map(item => item.type))];
            // console.log(types);
            var sum = '';
            var sum2 = '';
            this.Generador.forEach((element) => {
                const prop = element.prop;
                const js = element.js;
                sum = sum + prop + '\r\n';
                sum2 = sum2 + js + '\r\n';
            });
            this.entity.js = sum2;
            this.entity.prop = sum;
        },

        async save() {
            try {
                //* Validacion de form
                this.validate = validate(this.entity, this.rules);
                if (!this.validate.valid) {
                    throw 'Favor de validar los campos';
                }
                // Si el id es != 0 entonces actualizamos, si no, guardamos*/
                if (this.entity.id && this.entity.id > 0) {
                    // Actualizamos*/
                    let entity = await this.entity.update();
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Actualizar',
                        detail: 'Informacion actualizada con exito',
                        life: 3000,
                    });
                    // Modificamos el listado pah*/
                    let index = this.entities.findIndex((x) => x.id == this.entity.id);
                    this.entities[index] = entity;
                } else {
                    // Creamos uno nuevo*/
                    let entity = await this.entity.save();
                    // Agregamos un dato extra */
                    this.entities.push(entity);
                    this.$toast.add(
                        new Toast({
                            summary: 'Creacion',
                            detail: 'Informacion guardada con exito',
                        })
                    );
                }

                this.newDialog = false;

                let Parametros = new GeneratorCode(this.session).MakeCode(this.entity.prop, this.entity.js, this.entity.folder_father, this.entity.name, this.entity.vue, this.entity.carpetavue);
                console.log(Parametros);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Creacion',
                    detail: 'Codigo Generado con Exito',
                });

                this.entity = new GeneratorCode(this.session);
                this.newDialog = false;
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>


<style scoped lang="scss">
.table-header {
    display: flex;
    justify-content: space-between;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;
        .p-button {
            margin-bottom: 0.25rem;
        }
    }
}

img:hover {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
}

// #Code{
//     background-color: aqua;
// }
</style>