import axios from 'axios';
import {
    Abstract
} from './Abstract';

export class GeneratorCode extends Abstract {
    constructor(session) {
        super('general/GeneratorCode', session);
        this.name = null;
        this.folder_father = null;
        this.prop = null;
        this.js = null;
       this.carpetavue=null;
       this.vue=null;

    }
    async tablas() {
        let response = await axios.get(this.controller + "/tablas" , {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
              
            }
        });
        return response.data;
    }
    async balance(table) {
        let response = await axios.get(this.controller + "/balance" , {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                table:table
            }
        });
        return response.data;
    }
  
    async MakeCode(Prop, JS, folder_father, name,vue,CarpetaVue) {
        let response = await axios.post(this.controller +'/Vue', {
          prop: Prop,
          js: JS,
          folder_father: folder_father,
          name:name,
          vue:vue,
          CarpetaVue:CarpetaVue
        }, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch:  this.id_branch,

            }
        });
        return response.data;
      }
}